
.input-deep {
    margin: 0 12px 10px 17px;
}
.option-list {
    &::v-deep .el-radio__input {
        &.is-checked {
            .el-radio__inner {
                background-color: #1122D8;
                border-color: #1122D8;

            }
            &+.el-radio__label {
                color: #1122D8;
            }
        }
    }
}

