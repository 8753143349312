
.option-body {
    .option-list {
        .option-item {
            width: 300px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
            margin: 10px 0;
            .input-deep {
                margin: 0 6px 0 15px;
            }
            i {
                cursor: pointer;
            }
        }
    }
}

