
.option-list {
    .option-item {
        margin: 10px 0px;
        padding: 0 5px;
    }
    &::v-deep .el-radio__input {
        &.is-checked {
            .el-radio__inner {
                background-color: #1122D8;
                border-color: #1122D8;

            }
            &+.el-radio__label {
                color: #1122D8;
            }
        }
    }
}

